import axios from "axios";
import store from "@/store";
// create an axios instance
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // api of base_url
  timeout: 30000, // request timeout,
  // withCredentials: true,
});

// request interceptor
service.interceptors.request.use(
  (config) => {
    // Do something before request is sent
    if (store.getters.userIsLoggedIn) {
      config.headers["X-Auth-Token"] = `${store.getters.token}`;
    }
    return config;
  },
  (error) => {
    // Do something with request error
    Promise.reject(error);
  }
);

// response interceptor
service.interceptors.response.use(
  (response) => response,

  (error) => {
    // console.log("err" + error); // for debug
    if (error.code === "ECONNABORTED") {
      error.message = "Connection Timeout";
    } else if (error?.response?.data?.message) {
      error.message = error?.response?.data?.message;
    }
    return Promise.reject(error);
  }
);

export default service;
